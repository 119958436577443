import { hydrate } from "@/utils/form";

export default class NextoreAccount {
  club = null;
  apiToken = null;
  reference = null;
  username = null;
  password = null;

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if(options.serialize) {
        this.serialize(object);
      }
    }
  }

  serialize (object) {
    hydrate(this, object);
  }

}
