<template>
  <div>
    <d-body-top-bar :title="$t('admin.club_detail.nextore-config.configuration')">
      <div slot="tree">
        <label
          class="pointer link-area ml-1"
          @click="$router.push({ name: 'clubs'})"
        >
          {{ $t('admin.clubs.list') }} /
        </label>
        <label
          class="pointer inactive-tree link-area ml-1"
        >
          {{ $t('admin.club_detail.nextore-config.nextore-link') }}
        </label>
      </div>
      <div slot="icons">
      <span
        class="active-tree icons-body-size"
      >
        <i class="fa fa-navicon"></i>
      </span>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <b-card>
        <b-row>
          <b-col cols="12">
            <p class="description">
              {{ $t('admin.club_detail.nextore-config.description') }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" class="mt-2 mb-2">
            <label class="field-name-class">
              {{ $t('admin.club_detail.nextore-config.customer-number') }}
            </label>
            <d-text-field
              v-model="nextoreAccount.reference"
              size="sm"
              class-name="background-light-blue-inputs"
            />
          </b-col>

          <b-col cols="4" class="mt-2 mb-2">
            <label class="field-name-class">
              {{ $t('admin.club_detail.nextore-config.identifier') }}
            </label>
            <d-text-field
              v-model="nextoreAccount.username"
              size="sm"
              class-name="background-light-blue-inputs"
            />
          </b-col>

          <b-col cols="4" class="mt-2 mb-2">
            <label class="field-name-class">
              {{ $t('admin.club_detail.nextore-config.password') }}
            </label>
            <d-text-field
              v-model="nextoreAccount.password"
              type="password"
              size="sm"
              class-name="background-light-blue-inputs"
            />
          </b-col>
          <b-col cols="8" class="mt-2 mb-2">
            <label class="field-name-class">
              {{ $t('admin.club_detail.nextore-config.token') }}
            </label>
            <d-text-field
              v-model="nextoreAccount.apiToken"
              size="sm"
              class-name="background-light-blue-inputs"
            />
          </b-col>
        </b-row>
        <b-row align="right">
          <b-col
            align="right"
            class="mt-2 mb-2"
          >
            <d-button
              text="admin.club_detail.nextore-config.do-not-configure"
              class="d-btn-sm btn d-btn-danger font-text-title mr-2"
              @on:button-click="$router.replace({name: 'clubs'})"
            />
            <d-button
              text="general.actions.validate"
              class="d-btn-sm btn d-btn-primary font-text-title"
              @on:button-click="onValidate"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>

  </div>
</template>
<script>
import NextoreAccount from "@/classes/NextoreAccount";
import {postNextoreAccount} from "@api/services/nextore/account.api";

export default {
  data: ()=> ({
    nextoreAccount: new NextoreAccount(),
  }),
  methods: {
    onValidate() {
      this.nextoreAccount.club = '/clubs/' + this.$route.params.id;

      postNextoreAccount(this.nextoreAccount)
        .then(() => {
          this.nextoreAccount = new NextoreAccount();
          this.$router.push({ name: 'clubs'});
        })
      ;
    }
  },
}
</script>
<style scoped lang="scss">
@import "@lazy/_b-card.scss";
.description {
  text-align: left;
  font: normal normal 18px/27px Avenir;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.74);
  opacity: 1;
}
</style>
